import React from "react";
import styles from "./IconHolder.module.css";
import variants from "../Variants.module.css";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface IconHolderProps {
  variant?: string;
  icon?: IconProp;
  children?: string;
}

const IconHolder: React.FC<IconHolderProps> = (props) => {
  const { variant, icon, ...rest } = props;
  return (
    <div
      {...rest}
      className={`${styles.holder} ${
        variants[props.variant ? props.variant : "light"]
      }`}
    >
      {props.icon && (
        <span>{props.icon && <FontAwesomeIcon icon={props.icon} />}</span>
      )}
      {props.children && (
        <div className={styles.container}>{props.children}</div>
      )}
    </div>
  );
};

export default IconHolder;

import React from "react";
import { Oval } from "react-loader-spinner";

export function Loading() {
  return (
    <Oval
      height={80}
      width={80}
      color="#c1c1c1"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor="#c5c5c5"
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  );
}

export function ErrorMessage() {
  return <p>Ошибка</p>;
}

export function Error404() {
  return <p>404: объект не найден!</p>;
}

export function ForbiddenMessage() {
  return <p>Доступ ограничен. Обратитесь к менеджеру</p>;
}

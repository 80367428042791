import React from 'react';
import styles from './Button.module.css';
import variants from '../Variants.module.css';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface ButtonProps
  extends React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    React.AriaAttributes {
  variant?: string;
  size?: string;
  icon?: IconProp;
}

const Button: React.FC<ButtonProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <button
      {...rest}
      className={`${styles.btn} ${variants[props.variant ? props.variant : 'light']} ${props.size && styles[props.size]} ${props.className && props.className} ${
        props.disabled && styles.disabled
      }`}
      onClick={props.onClick}
    >
      {props.children && <div className={styles.container}>{props.children}</div>}
      {props.icon && <span>{props.icon && <FontAwesomeIcon icon={props.icon} />}</span>}
    </button>
  );
};

export default Button;

import React, { FC, InputHTMLAttributes } from "react";
import styles from "./Input.module.css";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  ref?: string;
}

const Input: FC<InputProps> = ({ name, value, ...otherProps }) => {
  return <input className={styles.input} value={value} {...otherProps} name={name} />;
};

export default Input;

import React from "react";
import styles from "./Alert.module.css";

type AlertProps = {
  variant: string;
  children: React.ReactNode;
};

const Alert = ({ variant, children }: AlertProps) => {
  return <div className={styles[variant]}>{children}</div>;
};

export default Alert;

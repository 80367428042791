import styles from "./Header.module.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type user = {
  name: String;
};
//TODO: set type for setOpen;
interface ButtonProps {
  currentUser?: user | null;
  children?: React.ReactNode;
  open: Boolean;
  setOpen: (value: any) => void;
}

const Mobile = (props: ButtonProps) => {
  const [currentUser] = useState(props.currentUser || {});
  return (
    <>
      {props.open && (
        <div className={`${styles.mobile}`}>
          <div
            className={styles.exit}
            onClick={() => {
              props.setOpen("");
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className={styles.container}>
            <h1 className={styles.title}>Меню</h1>
            {currentUser && (
              <>
                <Link
                  className={styles.link}
                  to="/survey"
                  onClick={() => props.open && props.setOpen(false)}
                >
                  Опросы
                </Link>
                <Link
                  className={styles.link}
                  to="/records"
                  onClick={() => props.open && props.setOpen(false)}
                >
                  Записи
                </Link>
                <Link
                  className={styles.link}
                  to="/forms"
                  onClick={() => props.open && props.setOpen(false)}
                >
                  Формы
                </Link>
                <Link
                  className={styles.link}
                  to="/references"
                  onClick={() => props.open && props.setOpen(false)}
                >
                  Справочники
                </Link>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Mobile;

import React from 'react';
import styles from './Logo.module.css';
import { LinkContainer } from 'react-router-bootstrap';

interface ButtonProps {
  title: string;
  logo: string;
}

const Logo = (props: ButtonProps) => {
  return (
    <LinkContainer to='/'>
      <div className={styles.logo}>
        {props.logo && (
          <div className={styles.img_wrapper}>
            <img className={styles.img} src={props?.logo} alt='logo' />
          </div>
        )}
        {props.title}
      </div>
    </LinkContainer>
  );
};

export default Logo;

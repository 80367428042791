import React, { lazy, Suspense, useState, useEffect } from "react";
import NavBar from "./components/UI/NavBar";
import { ForbiddenMessage, Loading } from "./components/UI/Widgets";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ErrorBoundary } from "./components/ErrorBoundary";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Outlet,
} from "react-router-dom";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";

const RecordListPage = lazy(() => import("./pages/RecordListPage"));
const SurveyListPage = lazy(() => import("./pages/SurveyListPage"));
const RecordPage = lazy(() => import("./pages/RecordPage"));
const FormListPage = lazy(() => import("./pages/FormSchema"));
const FormInputs = lazy(() => import("./pages/FormInputs"));
const AuthRequired = lazy(() => import("./pages/AuthRequired"));
const TemplatePage = lazy(() => import("./pages/TemplatePage"));
const SurveyPage = lazy(() => import("./pages/SurveyPage/index"));
const ReferencePage = lazy(() => import("./pages/ReferencePage"));
const ReferenceListPage = lazy(() => import("./pages/ReferenceList"));
const FormEditPage = lazy(() => import("./pages/FormEdit"));
const NoticeCreatePage = lazy(() => import("./pages/NoticeCreatePage"));

const token = new URLSearchParams(window.location.search).get("token");

const client = new ApolloClient({
  uri: "/graphql",
  cache: new InMemoryCache(),
  headers: token
    ? {
        authorization: `Bearer ${token}`,
      }
    : undefined,
});

interface userState {
  name: string;
}

function ProtectedRoute() {
  const currentUser = useState<userState | string>("");
  let navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      return navigate("/authRequired");
    }
  }, [currentUser, navigate]);

  return currentUser ? <Outlet /> : null;
}

export default function App() {
  const [currentUser, setCurrentUser] = useState<userState | string>("");
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    if (!currentUser && !loading) {
      return navigate("/authRequired");
    }
  }, [currentUser, navigate, loading]);

  return (
    <ApolloProvider client={client}>
      <div className="app">
        <NavBar
          currentUser={currentUser}
          setCurrentUser={setCurrentUser}
          setLoading={setLoading}
        />
        <ErrorBoundary>
          <div className="container-custom">
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route path="/authRequired" element={<AuthRequired />} />
                <Route element={<ProtectedRoute />}>
                  <Route path="/reference/:id" element={<ReferencePage />} />
                  <Route path="/reference" element={<ReferencePage />} />
                  <Route path="/references" element={<ReferenceListPage />} />
                  <Route path="/survey/:id" element={<SurveyPage />} />
                  <Route path="/survey" element={<SurveyListPage />} />
                  <Route path="/record/:id" element={<RecordPage />} />
                  <Route path="/records" element={<RecordListPage />} />
                  <Route path="/docs" element={<ForbiddenMessage />} />
                  <Route path="/claims" element={<ForbiddenMessage />} />
                  <Route path="/trainings" element={<ForbiddenMessage />} />
                  <Route path="/FormInputs/:id" element={<FormInputs />} />
                  <Route path="/form/:id" element={<FormEditPage />} />
                  <Route path="/notice/" element={<NoticeCreatePage />} />
                  <Route path="/form" element={<FormEditPage />} />
                  <Route path="/forms" element={<FormListPage />} />
                  <Route path="/template/:id" element={<TemplatePage />} />
                  <Route path="/" element={<FormListPage />} />
                  <Route path="*" element={<FormListPage />} />
                </Route>
              </Routes>
            </Suspense>
          </div>
        </ErrorBoundary>
      </div>
    </ApolloProvider>
  );
}

import React, { FocusEvent } from 'react';
import styles from './Select.module.css';

type InputProps = {
  id?: string;
  name?: string;
  value?: string | number;
  placeholder?: string;
  onChange?: (e:React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (e: FocusEvent<HTMLSelectElement>) => void;
  onFocus?: (e: FocusEvent<HTMLSelectElement>) => void;
  required?: boolean;
  children?: React.ReactNode;
};

const Select = (props: InputProps) => {
  return (
    <select
      className={styles.select}
      id={props.id}
      name={props.name}
      value={props.value}
      placeholder={props.placeholder}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      required={props.required ? true : false}
    >
      {props.children}
    </select>
  );
};

export default Select;

import React from 'react';
import styles from './ModalWindow.module.css';

type ModalProps = {
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
};

const Modal = ({ active, setActive, children }: ModalProps) => {
  return (
    active ? (
    <div
      className={`${styles.modal} ${active && styles.active}`}
      onClick={() => setActive(false)}
    >
      <div className={styles.content} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
    ) : <></>
  );
};

export default Modal;

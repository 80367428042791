import React from 'react';
import styles from './TextArea.module.css';

type InputProps = {
  id?: string;
  name?: string;
  value?: string;
  placeholder?: string;
  rows?: number;
  cols?: number;
  onChange?: any;
  onBlur?: any;
  onFocus?: any;
  required?: boolean;
  disabled?: boolean;
};

const TextArea = (props: InputProps) => {
  return (
    <textarea
      className={styles.textArea}
      id={props.id}
      rows={props.rows ? props.rows : 5}
      cols={props.cols ? props.cols : 13}
      name={props.name}
      value={props.value}
      placeholder={props.placeholder}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      required={props.required ? true : false}
      disabled={props.disabled ? true : false}
    ></textarea>
  );
};

export default TextArea;

import styles from "./Header.module.css";
import React, { useState } from "react";
import Logo from "../Logo";
import { Link } from "react-router-dom";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Login from "../../Login";
import Button from "../Button";
import Mobile from "./Mobile";

interface currentUser {
  name: string;
}

interface NavProps {
  children?: React.ReactNode;
  currentUser: { name: string } | string;
  setCurrentUser: React.Dispatch<React.SetStateAction<currentUser>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavBar = (props: NavProps) => {
  const [open, setOpen] = useState(false);
  const toggleHandler = () => {
    setOpen(!open);
  };

  return (
    <>
      <header className={`${styles.header}`}>
        <div className={`${styles.wrapper}`}>
          <div className={styles.container}>
            <div className={styles.child}>
              <Logo
                title="PS"
                logo="https://www.radium-it.ru/static/img/logo.svg"
              />
              <div className={styles.navigation}>
                {props.currentUser && (
                  <>
                    <Link className={styles.link} to="/survey">
                      Опросы
                    </Link>
                    <Link className={styles.link} to="/records">
                      Записи
                    </Link>
                    <Link className={styles.link} to="/forms">
                      Формы
                    </Link>
                    <Link className={styles.link} to="/references">
                      Справочники
                    </Link>
                  </>
                )}
              </div>
            </div>
            <div className={styles.child}>
              <Button
                className="hamburger"
                icon={faBars}
                onClick={toggleHandler}
              ></Button>
              <Login
                schema={
                  process.env.NODE_ENV !== "development"
                    ? window.location.hostname.substring(
                        0,
                        window.location.hostname.indexOf(".")
                      )
                    : "client"
                }
                onLogin={props.setCurrentUser}
                setLoading={props.setLoading}
                currentUser={props.currentUser}
              />
            </div>
          </div>
        </div>
      </header>
      <Mobile open={open} setOpen={setOpen} />
    </>
  );
};

export default NavBar;
